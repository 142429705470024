import React, { useState, useEffect } from "react";
import { Table, Select, Alert, DatePicker, Card, Row, Col, Input } from "antd";
import {
  fetchInvoiceDetailsReport,
  fetchProducts,
  fetchContacts
} from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";
import SearchableSelect from "../components/common/SearchableSelect";
import { Option } from "antd/es/mentions";

const { RangePicker } = DatePicker;

interface ProductsList {
  ID: number;
  BrandName: string;
}

interface Contact {
  id: number;
  name: string;
}

interface DataItem {
  InvoiceNo: string;
  InvoiceType: string;
  Description: string;
  Party: string;
  InvoiceDate: string;
  PayMode: string;
  Qty: number;
  Rate: number;
  Total: number;
  BrandName: string;
  ItemDescription: string;
  ProductType: string;
}

const ProductReport: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [productsList, setProductsList] = useState<ProductsList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProductID, setselectedProductID] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [InvoiceType, setInvoiceType] = useState<string>('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [PartyID, setPartyID] = useState<number>(0);
  const [productType, setProductType] = useState<string>('');

  // New state for Description filter
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    fetchProductsList();
    loadContacts();
  }, []);

  useEffect(() => {
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      selectedProductID,
      dateRange,
      InvoiceType,
      PartyID,
      description,
      productType
    );
  }, [pagination.current, pagination.pageSize, selectedProductID, InvoiceType, PartyID, dateRange, description, productType]);

  const fetchProductsList = async () => {
    try {
      const response = await fetchProducts();
      const allOption = { ID: 0, BrandName: "ALL" };
      setProductsList([allOption, ...response.responseObject.products]);
    } catch (error) {
      setError("An error occurred while fetching products. Please try again.");
    }
  };

  const loadContacts = async () => {
    setLoading(true);
    try {
      const data = await fetchContacts();
      if (Array.isArray(data.responseObject)) {
        const allOption = { id: 0, name: "ALL" };
        setContacts([allOption, ...data.responseObject]);
        setError(null);
      } else {
        setError("Error: Invalid data format");
      }
    } catch (error) {
      setError("Error fetching contacts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchTableData = async (
    page: number,
    pageSize: number,
    selectedProductID: number,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    InvoiceType: string,
    PartyID: number,
    description: string,
    productType: string

  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDate = dateRange
        ? dateRange[0]?.format("YYYY-MM-DD")
        : undefined;
      const toDate = dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined;
      setData([]);
      const response = await fetchInvoiceDetailsReport(
        page,
        pageSize,
        selectedProductID,
        fromDate,
        toDate,
        InvoiceType,
        PartyID,
        description,
        productType
      );

      setData(response.responseObject.invoiceData.data);
      setGrandTotal(response.responseObject.totalSum);
      setPagination({
        ...pagination,
        total: response.responseObject.invoiceData.total,
      });
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleSelectChange = (value: number) => {
    setselectedProductID(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handlePartyChange = (value: number) => {
    setPartyID(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleInvoiceType = (value: string) => {
    setInvoiceType(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleProductType = (value: string) => {
    setProductType(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  // New input handler for description filter
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
    },
    {
      title: "Invoice Type",
      dataIndex: "InvoiceType",
      key: "InvoiceType",
    },
    {
      title: "Party Name",
      dataIndex: "Party",
      key: "Party",
    },
    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
    },
    {
      title: "Product Name",
      dataIndex: "BrandName",
      key: "BrandName",
    },
    {
      title: "Product Type",
      dataIndex: "ProductType",
      key: "ProductType",
    },
    {
      title: "Description",
      dataIndex: "ItemDescription",
      key: "ItemDescription",
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "Rate",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
  ];

  return (
    <Card title="Product Report">
      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}
      <Row gutter={[16, 16]}>
        {/* New input for Description filter */}
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="description-input">Description</label>
          <Input
            type="text"
            placeholder="Enter description"
            value={description}
            onChange={handleDescriptionChange}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="product-select">Product</label>
          <SearchableSelect
            options={productsList}
            placeholder="Select a Product"
            onChange={handleSelectChange}
            idKey="ID"
            nameKey="BrandName"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="party-select">Party</label>
          <SearchableSelect
            options={contacts}
            placeholder="Select Party"
            onChange={handlePartyChange}
            idKey="id"
            nameKey="name"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="invoice-type-select">Invoice Type</label>
          <Select
            aria-labelledby="Invoice Type"
            menuItemSelectedIcon
            defaultValue=""
            style={{ width: "100%" }}
            onChange={handleInvoiceType}
            options={[
              { value: "", label: "ALL" },
              { value: "PURCHASE INVOICE", label: "PURCHASE INVOICE" },
              { value: "PURCHASE RETURN", label: "PURCHASE RETURN" },
              { value: "SALE INVOICE", label: "SALE INVOICE" },
              { value: "SALE RETURN", label: "SALE RETURN" },
            ]}
          ></Select>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="date-range-picker">Date Range</label>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: "100%" }}
            placeholder={["From Date", "To Date"]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="product-type">Product Type</label>
          <Select
            placeholder="Product Type"
            onChange={handleProductType}
            style={{ width: '100%' }}
            value={productType}
          >
            <Option value="">ALL</Option>
            <Option value="NEW">NEW</Option>
            <Option value="USED">USED</Option>
          </Select>

        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey="InvoiceNo"
            size="small"
            scroll={{ x: true }}
            bordered
            summary={(tableData) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <strong>Grand Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <strong>{grandTotal}</strong>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ProductReport;
